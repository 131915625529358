import { QuoteAdminStatuses } from "@/enums/QuoteAdminStatuses";
import { QuoteCustomerStatuses } from "@/enums/QuoteCustomerStatuses";

const quoteCustomerAllStatuses = [
  {
    key: QuoteCustomerStatuses.NONE,
    statusIndex: QuoteAdminStatuses.Draft,
    value: "",
  },
  {
    key: QuoteCustomerStatuses.Draft,
    statusIndex: QuoteAdminStatuses.NONE,
    value: "Draft",
  },
  {
    key: QuoteCustomerStatuses.Submitted,
    statusIndex: QuoteAdminStatuses.Received,
    value: "Submitted",
  },
  {
    key: QuoteCustomerStatuses.InReview,
    statusIndex: QuoteAdminStatuses.Costing,
    value: "In review",
  },
  {
    key: QuoteCustomerStatuses.InReview,
    statusIndex: QuoteAdminStatuses.Authorization,
    value: "In review",
  },
  {
    key: QuoteCustomerStatuses.InReview,
    statusIndex: QuoteAdminStatuses.Confirmed,
    value: "In review",
  },
  {
    key: QuoteCustomerStatuses.QuoteReady,
    statusIndex: QuoteAdminStatuses.Quoted,
    value: "Quote ready",
  },
  {
    key: QuoteCustomerStatuses.Checkout,
    statusIndex: QuoteAdminStatuses.Checkout,
    value: "Checkout",
  },
  {
    key: QuoteCustomerStatuses.PartiallyOrdered,
    statusIndex: QuoteAdminStatuses.PartiallyOrder,
    value: "Partially ordered",
  },
  {
    key: QuoteCustomerStatuses.Ordered,
    statusIndex: QuoteAdminStatuses.Ordered,
    value: "Ordered",
  },
];

export default quoteCustomerAllStatuses;
