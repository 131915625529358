import { AxiosResponse } from "axios";
import ApiService from "@/services/index";
import { StickerParamsInterface } from "@/types/stickers/StickerParamsInterface";

export default class StickersService {
  static getStickers(params: StickerParamsInterface): Promise<AxiosResponse> {
    return ApiService.get("/v1/stickers", { params });
  }

  static changeStatusSticker(
    params: StickerParamsInterface
  ): Promise<AxiosResponse> {
    const { labelStatus, id } = params;
    return ApiService.patch(`/v1/stickers/${id}`, {
      labelStatus,
    });
  }
}
