import { usePluralize } from "@/composables/usePluralize";
import quoteCustomerAllStatuses from "@/constants/quoteCustomerAllStatuses";
import { QuoteAdminStatuses } from "@/enums/QuoteAdminStatuses";
import { SubtitleByStatus } from "@/enums/SubtitleByStatus";

export const getCustomerStatusName = (statusNumber: number) => {
  return quoteCustomerAllStatuses.find(
    (item) => item.statusIndex === statusNumber
  )?.value;
};

export const getSubtitleByStatus = (
  statusNumber: number,
  count = 0
): { text: string; link?: string } => {
  if (statusNumber === QuoteAdminStatuses.Quoted) {
    return {
      text: count
        ? `${count} technical ${usePluralize(count, "feedback")}`
        : "",
    };
  } else {
    return SubtitleByStatus[statusNumber as keyof typeof SubtitleByStatus];
  }
};
