import { QuoteAdminStatuses } from "./QuoteAdminStatuses";

export const SubtitleByStatus = {
  [QuoteAdminStatuses.Draft]: {
    text: "Submit and get fast quote and technical feedback",
  },
  [QuoteAdminStatuses.NONE]: {
    text: "Submit and get fast quote and technical feedback",
  },
  [QuoteAdminStatuses.Received]: {
    text: "Quote submitted, our engineers will get right on it!",
  },
  [QuoteAdminStatuses.Costing]: {
    text: "Quote submitted, our engineers will get right on it!",
  },
  [QuoteAdminStatuses.Authorization]: {
    text: "Quote submitted, our engineers will get right on it!",
  },
  [QuoteAdminStatuses.Confirmed]: {
    text: "Quote submitted, our engineers will get right on it!",
  },
  [QuoteAdminStatuses.Checkout]: { text: "Continue checkout" },
  [QuoteAdminStatuses.PartiallyOrder]: {
    text: "Track your order",
    link: "/",
    hidden: true,
  },
  [QuoteAdminStatuses.Ordered]: {
    text: "Track your order",
    link: "/",
    hidden: true,
  },
};
