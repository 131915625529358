import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "create-new-quote-modal main-modal",
    preset: "dialog",
    "show-icon": false,
    title: "Select customer"
  }, {
    default: _withCtx(() => [
      _createVNode($setup["SelectCustomer"], {
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('customerChecked')))
      })
    ]),
    _: 1
  }))
}