import { UserTypes } from "@/enums/UserTypes";
import { MemberInterface } from "@/types/MemberInterface";

export const sortTeamMembers = (
  members: MemberInterface[],
  length?: number
): MemberInterface[] => {
  const customers = getSortedMembers(members, UserTypes.customer);
  const internals = getSortedMembers(members, UserTypes.internal);
  const membersArray = [...customers, ...internals];
  return length ? membersArray.slice(0, length) : membersArray;
};

export const getSortedMembers = (
  members: MemberInterface[],
  type: UserTypes
): MemberInterface[] => {
  return members
    .filter((item) => item.userId.type === type)
    ?.sort((a, b) => a.userId.firstName.localeCompare(b.userId.firstName));
};
