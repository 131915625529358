import { createVNode as _createVNode, vShow as _vShow, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-759c5310"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "download-purchase-order" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Loader"], { loading: $setup.loading }, null, 8, ["loading"]),
    _withDirectives(_createElementVNode("div", {
      class: "link",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.onPurchaseOrderDownloadClick()), ["stop"]))
    }, "Download PO", 512), [
      [_vShow, !$setup.loading]
    ])
  ]))
}