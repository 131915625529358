import complexityLevels from "@/constants/complexityLevels";
import { ComplexityLevel } from "@/enums/ComplexityLevel";

export const getComplexityLevel = (code: number): string => {
  const levelElement = complexityLevels.find((item) => item.key === code);
  return levelElement?.value || complexityLevels[0].value;
};

export const getTagStyle = (code: number): string => {
  const status = getComplexityLevel(code);
  switch (status) {
    case ComplexityLevel.low:
      return "success";
    case ComplexityLevel.middle:
      return "warning";
    default:
      return "error";
  }
};
