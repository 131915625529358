export enum QuoteCustomerStatuses {
  NONE = 0,
  Draft = 1,
  Submitted = 2,
  InReview = 3,
  QuoteReady = 6,
  Checkout = 7,
  PartiallyOrdered = 8,
  Ordered = 9,
}
