import { computed, ref, watch, Ref } from "vue";

import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import { promisedQuoteTime } from "@/constants/quoteTime";
import { dateFormatSymbol } from "@/constants/dateFormatSymbol";

dayjs.extend(relativeTime);
dayjs.extend(duration);

export default () => {
  const receivedTimeRfqTimeComposable: Ref<Dayjs | undefined> = ref();
  const quotedTimeRfqTimeComposable: Ref<Dayjs | undefined> = ref();
  const orderedTimeRfqTimeComposable: Ref<Dayjs | undefined> = ref();

  const timeToQuot = computed(() => {
    return dayjs(receivedTimeRfqTimeComposable.value).add(
      promisedQuoteTime,
      "day"
    );
  });

  const timeToQuoteDiff = ref();
  const withDelay = computed(() => {
    return !isQuoted.value && dayjs().isAfter(timeToQuot.value);
  });

  const getFormattedValue = (value: number | null, formatIndex: number) => {
    return value ? `${value}${dateFormatSymbol[formatIndex]}` : "";
  };

  const getFormattedTimeDiff = (
    firstDate: Dayjs,
    secondDate: Dayjs = dayjs()
  ) => {
    const datesDifference = Math.abs(firstDate.diff(secondDate, "ms"));
    const dateValues = dayjs
      .duration(datesDifference)
      .format("YYYY M D HH mm")
      .split(" ")
      .map((element) => +element);
    const firstValueIndex = dateValues.findIndex((item) => item);
    const showSecondValue = firstValueIndex < dateFormatSymbol.length - 1;

    const firstValue = getFormattedValue(
      dateValues[firstValueIndex],
      firstValueIndex
    );
    const secondValue = getFormattedValue(
      showSecondValue ? dateValues[firstValueIndex + 1] : null,
      firstValueIndex + 1
    );
    return `${firstValue} ${secondValue}`;
  };

  const isQuoted = computed(() => {
    return !!quotedTimeRfqTimeComposable.value;
  });

  const isOrdered = computed(() => {
    return !!orderedTimeRfqTimeComposable.value;
  });

  watch(
    () => [
      isQuoted.value,
      receivedTimeRfqTimeComposable.value,
      quotedTimeRfqTimeComposable.value,
      orderedTimeRfqTimeComposable.value,
    ],
    () => {
      timeToQuoteDiff.value = getFormattedTimeDiff(timeToQuot.value);
    }
  );

  const quotedTimeMessage = computed(() => {
    return isQuoted.value && quotedTimeRfqTimeComposable.value
      ? getFormattedTimeDiff(
          quotedTimeRfqTimeComposable.value,
          receivedTimeRfqTimeComposable.value
        )
      : "";
  });

  const orderedTimeMessage = computed(() => {
    return isOrdered.value && orderedTimeRfqTimeComposable.value
      ? getFormattedTimeDiff(
          orderedTimeRfqTimeComposable.value,
          receivedTimeRfqTimeComposable.value
        )
      : "";
  });

  const timeToQuoteMessage = computed(() => {
    return timeToQuoteDiff.value + (withDelay.value ? " late" : "");
  });

  const timeMessage = computed(() => {
    return isOrdered.value
      ? orderedTimeMessage.value
      : isQuoted.value
      ? quotedTimeMessage.value
      : timeToQuoteMessage.value;
  });

  return {
    receivedTimeRfqTimeComposable,
    quotedTimeRfqTimeComposable,
    orderedTimeRfqTimeComposable,
    withDelay,
    isQuoted,
    isOrdered,
    timeMessage,
    getFormattedTimeDiff,
  };
};
